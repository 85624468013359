import * as auth from "modules/auth/reducers";
import * as billing from "modules/billing/reducers";
import * as enterprises from "modules/enterprises/reducers";
import * as notifications from "modules/notifications/reducers";
import * as polling from "modules/polling/reducers";
import * as users from "modules/users/reducers";
import * as projects from "modules/projects/reducers";
import { combineReducers } from "redux";

export type RootReducer = {
  auth: auth.Reducer;
  notifications: notifications.Reducer;
  polling: polling.Reducer;
  billing: billing.Reducer;
  users: users.Reducer;
  enterprises: enterprises.Reducer;
  projects: projects.Reducer;
};

export const rootReducer = combineReducers<RootReducer>({
  auth: auth.reducer,
  notifications: notifications.reducer,
  polling: polling.reducer,
  billing: billing.reducer,
  users: users.reducer,
  enterprises: enterprises.reducer,
  projects: projects.reducer
});

import { AxiosResponse } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  GenerateBillingDataReportParams,
  GetOrganizationBillingDataParams,
  GetOrganizationBillingDataResponse,
  GetProjectBillingDatabasesDataParams,
  GetProjectBillingDatabasesDataResponse,
  GetProjectBillingInstancesDataParams,
  GetProjectBillingInstancesDataResponse,
  GetProjectBillingVolumesDataParams,
  GetProjectBillingVolumesDataResponse,
  GetUserBillingDataParams,
  GetUserBillingDataResponse
} from "./types";

const serviceApiPath = `gotham-wayne-tower/method/`;

export function* getProjectBillingInstancesDataSaga(
  action: Action<GetProjectBillingInstancesDataParams>
): SagaIterator<void> {
  try {
    const { startDate, endDate, organizationId, projectId, regionId } =
      action.payload;
    const response: AxiosResponse<GetProjectBillingInstancesDataResponse> =
      yield call(
        axiosInstance.get,
        `${serviceApiPath}/admin/billing/organizations/${organizationId}/regions/${regionId}/projects/${projectId}/instances`,
        {
          params: {
            start: startDate,
            end: endDate
          }
        }
      );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getProjectBillingInstancesData.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getProjectBillingInstancesData.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get project instances billing data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getProjectBillingVolumesDataSaga(
  action: Action<GetProjectBillingVolumesDataParams>
): SagaIterator<void> {
  try {
    const { startDate, endDate, organizationId, projectId, regionId } =
      action.payload;
    const response: AxiosResponse<GetProjectBillingVolumesDataResponse> =
      yield call(
        axiosInstance.get,
        `${serviceApiPath}/admin/billing/organizations/${organizationId}/regions/${regionId}/projects/${projectId}/volumes`,
        {
          params: {
            start: startDate,
            end: endDate
          }
        }
      );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getProjectBillingVolumesData.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getProjectBillingVolumesData.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get project volumes billing data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getProjectBillingDatabasesDataSaga(
  action: Action<GetProjectBillingDatabasesDataParams>
): SagaIterator<void> {
  try {
    const { startDate, endDate, organizationId, projectId, regionId } =
      action.payload;
    const response: AxiosResponse<GetProjectBillingDatabasesDataResponse> =
      yield call(
        axiosInstance.get,
        `${serviceApiPath}/admin/billing/organizations/${organizationId}/regions/${regionId}/projects/${projectId}/databases`,
        {
          params: {
            start: startDate,
            end: endDate
          }
        }
      );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getProjectBillingDatabasesData.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getProjectBillingDatabasesData.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get project databases billing data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getOrganizationBillingDataSaga(
  action: Action<GetOrganizationBillingDataParams>
): SagaIterator<void> {
  try {
    const { id, startDate, endDate } = action.payload;
    const response: AxiosResponse<GetOrganizationBillingDataResponse> =
      yield call(
        axiosInstance.get,
        `${serviceApiPath}admin/billing/organizations/${id}`,
        {
          params: {
            start: startDate,
            end: endDate
          }
        }
      );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getOrganizationBillingData.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getOrganizationBillingData.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get organization billing data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getUserBillingDataSaga(
  action: Action<GetUserBillingDataParams>
): SagaIterator<void> {
  try {
    const { startDate, endDate, userId } = action.payload;
    const response: AxiosResponse<GetUserBillingDataResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}admin/billing/users/${userId}`,
      {
        params: {
          start: startDate,
          end: endDate
        }
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getUserBillingData.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getUserBillingData.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get user billing data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* generateBillingDataReportSaga(
  action: Action<GenerateBillingDataReportParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to generate report...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { startDate, endDate, region } = action.payload;
    yield call(
      axiosInstance.post,
      `${serviceApiPath}admin/billing/generate-report`,
      null,
      {
        params: {
          start: startDate,
          end: endDate,
          region: region
        }
      }
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to generate report has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
    yield put(
      actions.generateBillingDataReport.done({
        params: action.payload
      })
    );
  } catch (e) {
    yield put(
      actions.generateBillingDataReport.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to generate report",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLatest(
      actions.getProjectBillingInstancesData.started,
      getProjectBillingInstancesDataSaga
    ),
    takeLatest(
      actions.getProjectBillingVolumesData.started,
      getProjectBillingVolumesDataSaga
    ),
    takeLatest(
      actions.getProjectBillingDatabasesData.started,
      getProjectBillingDatabasesDataSaga
    ),
    takeLatest(
      actions.getOrganizationBillingData.started,
      getOrganizationBillingDataSaga
    ),
    takeLatest(actions.getUserBillingData.started, getUserBillingDataSaga),
    takeEvery(
      actions.generateBillingDataReport.started,
      generateBillingDataReportSaga
    )
  ]);
}

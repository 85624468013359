import { createTheme, PaletteMode, Theme } from "@mui/material";

export const getTheme = (
  mode: PaletteMode,
  customConfig?: AppConfig["theme"]
): Theme =>
  createTheme({
    palette: {
      mode,
      primary: {
        main:
          customConfig?.primaryColor ||
          (mode === "dark" ? "#0078c0" : "#00648c")
      },
      secondary: {
        main:
          customConfig?.secondaryColor ||
          (mode === "dark" ? "#0092cf" : "#0092cf")
      },
      grey: {
        100: customConfig?.tableHeadersColor || "#eef3f6",
        900: "#17222d"
      },
      ...(customConfig?.backgroundColor
        ? {
            background: {
              default: customConfig?.backgroundColor
            }
          }
        : {})
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true
        }
      },
      MuiLink: {
        defaultProps: {
          underline: "hover",
          color:
            customConfig?.secondaryColor ||
            (mode === "dark" ? "#0092cf" : "#00648c")
        }
      },
      MuiToggleButton: {
        defaultProps: {
          sx: {
            "&.Mui-selected": {
              // bgcolor: mode === "dark" ? "primary.main" : "primary",
              color: mode === "dark" ? "primary.contrastText" : "primary"
            }
          }
        }
      }
    }
  });

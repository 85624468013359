import { PROJECT_STATUSES } from "components/BillingOrganization/types";
import { ORG_STATUSES } from "components/User/types";
import { ROLES } from "modules/enterprises/types";
import { appConfig } from "./appConfig";

export const ELEMENT_IDS = {
  REACT_ROOT: "root",
  CONTENT_CONTAINER: "content-container",
  BREADCRUMBS_CONTAINER: "breadcrumbs-container"
};

export const ROUTES = {
  ROOT: "/",
  USERS: "/users",
  USER: "/users/:userId",
  BILLING_PROJECT:
    "/users/:userId/organizations/:organizationId/regions/:regionId/projects/:projectId",
  ORGANIZATIONS: "/organizations",
  ORGANIZATION: "/organizations/:organizationId",
  BILLING_ORGANIZATION: "/users/:userId/organizations/:organizationId",
  GROUP: "/group",
  PROJECTS: "/projects",
  PROJECT: "/regions/:region/projects/:projectId",
  VIRTUAL_MACHINES: "/virtual-machines",
  CLUSTERS: "/clusters",
  HYPERVISORS_OVERVIEW: "/hypervisors-overview",
  RAM: "/ram",
  CPU: "/cpu",
  DISK_SPACE: "/disk_space"
};

export const REGEX = {
  EMAIL_ADDRESS:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // source: https://emailregex.com
  PASSWORD:
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/,
  BILLING_RECORD_COST_KEY: /^cost_[a-z]{3}$/,
  BILLING_RECORD_TOTAL_COST_KEY: /^total-cost-[a-z]{3}$/,
  NAME_DOES_NOT_START_WITH_WHITESPACE: /^[^ ].*$/,
  NAME_DOES_NOT_END_WITH_WHITESPACE: /^.*[^ ]$/,
  NAME_DOES_NOT_CONTAIN_SEQUENTIAL_WHITESPACES: /^(?!.* {2}).*$/,
  NAME_STARTS_WITH_LETTER: /^[A-Za-z].*$/,
  ORGANIZATION_NAME: /^[^#]+$/,
  PROJECT_NAME: /^[^#:]+$/,
  GROUP_NAME: /^[^#]+$/
};

export enum DATE_FORMATS {
  ISO_DATE = "yyyy-MM-dd",
  DATE = "d MMM yyyy",
  DATETIME = "d MMM yyyy HH:mm:ss",
  TIME = "HH:mm:ss"
}

export const DEFAULT_CURRENCY = "eur";
export const DEFAULT_REGION = { name: "All regions", id: "all" };
export const DEFAULT_USERS_TO_SHOW = "users";

export const DEFAULT_PAGINATION_LIMIT = 10;
export const MAX_PAGINATION_LIMIT = 100;
export const DEFAULT_DEBOUNCE_DELAY = 1000; // in milliseconds
export const DEFAULT_TRIAL_DAYS = 60;

export const ORG_STATUSES_LABELS = {
  [ORG_STATUSES.ACTIVE]: "Active",
  [ORG_STATUSES.DELETED]: "Deleted"
};

export const PROJECT_STATUSES_LABELS = {
  [PROJECT_STATUSES.ACTIVE]: "Active",
  [PROJECT_STATUSES.DELETED]: "Deleted"
};

export const ERROR_MESSAGES = {
  REQUIRED: "Field is required.",
  EMAIL_ADDRESS: "E-mail address has not valid format.",
  PASSWORD:
    "Password must contain at least one Latin letter in upper case (A-Z), one Latin letter in lower case (a-z), digit (0-9) and special character ( !\"#$%&'()*+,-./:;<=>?@[]^_`{|}~) and must be at least 8 characters long.",
  PASSWORDS_MUST_MATCH: "Passwords must match.",
  NAME_DOES_NOT_START_WITH_WHITESPACE: "Name must not start with whitespace.",
  NAME_DOES_NOT_END_WITH_WHITESPACE: "Name must not end with whitespace.",
  NAME_DOES_NOT_CONTAIN_SEQUENTIAL_WHITESPACES:
    "Name must not contain sequential whitespaces.",
  NAME_DOES_NOT_CONTAIN_COLON: "Name must not contain colon.",
  NAME_DOES_NOT_CONTAIN_NUMBER_SIGN: "Name must not contain number sign.",
  NAME_STARTS_WITH_LETTER: "Name must start with Latin letter (a-z, A-Z).",
  ORGANIZATION_NAME: "Name must not contain number sign (#).",
  ORGANIZATIONS_NUMBER: `The allowed number of organizations cannot be less than ${appConfig.defaultAllowedOrgNumber}.`,
  PROJECTS_NUMBER: `The allowed number of projects cannot be less than ${appConfig.defaultAllowedProjectNumber}.`,
  PROJECT_NAME: "Name must not contain number sign (#) or colon (:).",
  GROUP_NAME: "Name must not contain number sign (#).",
  TRIAL_DAYS: `The allowed number of trial days cannot be less than 0 and more than 365 days.`
};

export const ADMIN_ROLES = {
  USER_MANAGEMENT: "user_management", // organizations, projects within org, users
  OPERATOR: "operator", // tab projects all actions, tab users only see info
  OBSERVE: "observe",
  BILLING: "billing", // billing tab in users, generate billing report
  ADMIN_ROLES_MANAGEMENT: "admin_roles_management" // set admins (but only with user_management)
};

export const ENTITY_NAME_LENGTH = 255;
export const PROJECT_NAME_LENGTH = 100;

export const ROLES_IN_ORG = {
  [ROLES.ADMIN]: "Administrator",
  [ROLES.MEMBER]: "Member",
  [ROLES.OWNER]: "Owner"
};

export const USERS_FILTER_OPTIONS = [
  { value: "users", label: "All Users" },
  { value: "trial", label: "Trial Users" },
  { value: "internal", label: "Internal Users" },
  { value: "admins", label: "Cloud Admins" },
  { value: "owners", label: "Organization Owners" }
];

export const ADMIN_REMINDER_INTERVAL_IN_MIN = 60;

import actionCreatorFactory from "typescript-fsa";
import {
  CreateUserParams,
  CreateUserResponse,
  GetUsersParams,
  GetUsersResponse,
  GetUserInfoParams,
  GetUserInfoResponse,
  SetUsersPasswdParams,
  GetUserOrganizationsParams,
  GetUserOrganizationsResponse,
  UpdateUserInfoParams,
  UpdateUserInfoResponse,
  ModifyUserEnablingParams
} from "./types";

const actionCreator = actionCreatorFactory("USERS");

export const getUsers = actionCreator.async<
  GetUsersParams,
  GetUsersResponse,
  unknown
>("GET_USERS");

export const getUserInfo = actionCreator.async<
  GetUserInfoParams,
  GetUserInfoResponse,
  unknown
>("GET_USER_INFO");

export const getUserOrganizations = actionCreator.async<
  GetUserOrganizationsParams,
  GetUserOrganizationsResponse,
  unknown
>("GET_USER_ORGANIZATIONS");

export const createUser = actionCreator.async<
  CreateUserParams,
  CreateUserResponse,
  unknown
>("CREATE_USER");

export const setUsersPassword = actionCreator.async<
  SetUsersPasswdParams,
  void,
  unknown
>("SET_USERS_PASSWD");

export const updateUserInfo = actionCreator.async<
  UpdateUserInfoParams,
  UpdateUserInfoResponse,
  unknown
>("UPDATE_USER_INFO");

export const updateAdminRoles = actionCreator.async<
  UpdateUserInfoParams,
  UpdateUserInfoResponse,
  unknown
>("UPDATE_ADMIN_ROLES");

export const modifyUserEnabling = actionCreator.async<
  ModifyUserEnablingParams,
  void,
  unknown
>("MODIFY_USER_ENABLING");

export const clearUserOrganizations = actionCreator<void>(
  "CLEAR_USER_ORGANIZATIONS"
);

export const clearUserInfo = actionCreator<void>("CLEAR_USER_INFO");

export const clear = actionCreator<void>("CLEAR");

import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import {
  OrganizationBillingData,
  ProjectBillingDatabasesData,
  ProjectBillingInstancesData,
  ProjectBillingVolumesData,
  UserBillingData
} from "./types";

export type Reducer = {
  organizationBillingData: OrganizationBillingData | null;
  userBillingData: UserBillingData | null;
  projectBillingInstancesData: ProjectBillingInstancesData | null;
  isProjectBillingInstancesDataLoading: boolean;
  projectBillingVolumesData: ProjectBillingVolumesData | null;
  isProjectBillingVolumesDataLoading: boolean;
  projectBillingDatabasesData: ProjectBillingDatabasesData | null;
  isProjectBillingDatabasesDataLoading: boolean;
  isOrganizationBillingDataLoading: boolean;
  isUserBillingDataLoading: boolean;
  isBillingDataReportGenerating: boolean;
};

const initialState: Reducer = {
  organizationBillingData: null,
  userBillingData: null,
  projectBillingInstancesData: null,
  isProjectBillingInstancesDataLoading: false,
  projectBillingVolumesData: null,
  isProjectBillingVolumesDataLoading: false,
  projectBillingDatabasesData: null,
  isProjectBillingDatabasesDataLoading: false,
  isOrganizationBillingDataLoading: false,
  isUserBillingDataLoading: false,
  isBillingDataReportGenerating: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getOrganizationBillingData.started,
    (state): Reducer => ({
      ...state,
      isOrganizationBillingDataLoading: true
    })
  )
  .case(
    actions.getOrganizationBillingData.done,
    (state, payload): Reducer => ({
      ...state,
      organizationBillingData: payload.result,
      isOrganizationBillingDataLoading: false
    })
  )
  .case(
    actions.getOrganizationBillingData.failed,
    (state): Reducer => ({
      ...state,
      isOrganizationBillingDataLoading: false
    })
  )

  .case(
    actions.getUserBillingData.started,
    (state): Reducer => ({
      ...state,
      isUserBillingDataLoading: true
    })
  )
  .case(
    actions.getUserBillingData.done,
    (state, payload): Reducer => ({
      ...state,
      userBillingData: payload.result,
      isUserBillingDataLoading: false
    })
  )
  .case(
    actions.getUserBillingData.failed,
    (state): Reducer => ({
      ...state,
      isUserBillingDataLoading: false
    })
  )

  .case(
    actions.generateBillingDataReport.started,
    (state): Reducer => ({
      ...state,
      isBillingDataReportGenerating: true
    })
  )
  .case(
    actions.generateBillingDataReport.done,
    (state): Reducer => ({
      ...state,
      isBillingDataReportGenerating: false
    })
  )
  .case(
    actions.generateBillingDataReport.failed,
    (state): Reducer => ({
      ...state,
      isBillingDataReportGenerating: false
    })
  )

  .case(
    actions.getProjectBillingInstancesData.started,
    (state): Reducer => ({
      ...state,
      isProjectBillingInstancesDataLoading: true
    })
  )
  .case(
    actions.getProjectBillingInstancesData.done,
    (state, payload): Reducer => ({
      ...state,
      projectBillingInstancesData: payload.result,
      isProjectBillingInstancesDataLoading: false
    })
  )
  .case(
    actions.getProjectBillingInstancesData.failed,
    (state): Reducer => ({
      ...state,
      isProjectBillingInstancesDataLoading: false
    })
  )

  .case(
    actions.getProjectBillingVolumesData.started,
    (state): Reducer => ({
      ...state,
      isProjectBillingVolumesDataLoading: true
    })
  )
  .case(
    actions.getProjectBillingVolumesData.done,
    (state, payload): Reducer => ({
      ...state,
      projectBillingVolumesData: payload.result,
      isProjectBillingVolumesDataLoading: false
    })
  )
  .case(
    actions.getProjectBillingVolumesData.failed,
    (state): Reducer => ({
      ...state,
      isProjectBillingVolumesDataLoading: false
    })
  )

  .case(
    actions.getProjectBillingDatabasesData.started,
    (state): Reducer => ({
      ...state,
      isProjectBillingDatabasesDataLoading: true
    })
  )
  .case(
    actions.getProjectBillingDatabasesData.done,
    (state, payload): Reducer => ({
      ...state,
      projectBillingDatabasesData: payload.result,
      isProjectBillingDatabasesDataLoading: false
    })
  )
  .case(
    actions.getProjectBillingDatabasesData.failed,
    (state): Reducer => ({
      ...state,
      isProjectBillingDatabasesDataLoading: false
    })
  )

  .case(actions.clear, (): Reducer => initialState);

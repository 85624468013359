import actionCreatorFactory from "typescript-fsa";
import {
  CreateProjectParams,
  CreateProjectResponse,
  DeleteProjectParams,
  GetProjectParams,
  GetProjectQuotasParams,
  GetProjectQuotasResponse,
  GetProjectResponse,
  GetProjectsParams,
  GetProjectsResponse,
  SetProjectQuotasParams,
  SetProjectQuotasResponse,
  UpdateProjectParams,
  UpdateProjectResponse
} from "./types";

const actionCreator = actionCreatorFactory("PROJECTS");

export const getProject = actionCreator.async<
  GetProjectParams,
  GetProjectResponse,
  unknown
>("GET_PROJECT");

export const getProjects = actionCreator.async<
  GetProjectsParams,
  GetProjectsResponse,
  unknown
>("GET_PROJECTS");

export const createProject = actionCreator.async<
  CreateProjectParams,
  CreateProjectResponse,
  unknown
>("CREATE_PROJECT");

export const updateProject = actionCreator.async<
  UpdateProjectParams,
  UpdateProjectResponse,
  unknown
>("UPDATE_PROJECT");

export const deleteProject = actionCreator.async<
  DeleteProjectParams,
  void,
  unknown
>("DELETE_PROJECT");

export const setProjectQuotas = actionCreator.async<
  SetProjectQuotasParams,
  SetProjectQuotasResponse,
  unknown
>("SET_PROJECT_QUOTAS");

export const getProjectQuotas = actionCreator.async<
  GetProjectQuotasParams,
  GetProjectQuotasResponse,
  unknown
>("GET_PROJECT_QUOTAS");

export const clear = actionCreator<void>("CLEAR");

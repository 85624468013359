import { FC } from "react";
import { CustomSelectProps } from "./types";
import { InputLabel, Select, SelectChangeEvent } from "@mui/material";
import * as s from "./styles";

export const CustomSelect: FC<CustomSelectProps> = ({
  label,
  options,
  defaultValue,
  onChange,
  size = "small"
}: CustomSelectProps) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value);
  };

  return (
    <s.ShowUsersFormControl variant="outlined" size={size}>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        defaultValue={defaultValue}
        onChange={handleChange}
        size={size}
        style={{
          fontSize: "0.9em"
        }}
      >
        {options.map((option) => (
          <s.ShowUsersItem key={option.value} value={option.value}>
            {option.label}
          </s.ShowUsersItem>
        ))}
      </Select>
    </s.ShowUsersFormControl>
  );
};

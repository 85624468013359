import { formatDate } from "utils/formatDate";
import { formatRegion } from "utils/formatRegion";
import {
  DATE_FORMATS,
  ORG_STATUSES_LABELS,
  PROJECT_STATUSES_LABELS
} from "../../constants";
import { RootReducer } from "../../reducers";
import {
  BILLING_RESOURCES_TYPES,
  BILLING_RESOURCES_TYPES_TITLES,
  OrganizationBillingData,
  ProjectBillingDatabasesData,
  ProjectBillingInstancesData,
  ProjectBillingVolumesData,
  TableBillingDatabasesRecord,
  TableBillingInstancesRecord,
  TableBillingSummaryRecord,
  TableBillingVolumesRecord,
  TableOrganizationBillingData,
  TableOrganizationProjectBillingData,
  TableTotalBillingDatabasesRecord,
  TableTotalBillingInstancesRecord,
  TableTotalBillingSummaryRecord,
  TableTotalBillingVolumesRecord,
  TableTotalOrganizationBillingData,
  TableTotalOrganizationProjectBillingData,
  UserBillingData
} from "./types";
import { createSelector } from "reselect";

const formatCosts = (costsData?: Record<string, number>) => {
  return costsData
    ? Object.keys(costsData).reduce(
        (acc, cur) => ({
          ...acc,
          [`cost-${cur}`]: costsData[cur].toFixed(2)
        }),
        {}
      )
    : {};
};

export const tableProjectsBillingDataSelector = (
  state: RootReducer
): TableOrganizationProjectBillingData[] | null =>
  state.billing.organizationBillingData?.projects.map((project) => ({
    id: project.id,
    name: project.name,
    region: formatRegion(project.region),
    status: PROJECT_STATUSES_LABELS[project.status],
    ...formatCosts(project.costs)
  })) || null;

export const tableProjectsTotalBillingDataSelector = (
  state: RootReducer
): TableTotalOrganizationProjectBillingData | null =>
  state.billing.organizationBillingData &&
  state.billing.organizationBillingData.projects.length > 0
    ? {
        id: "__total",
        name: "Total",
        region: "",
        status: "",
        ...formatCosts(state.billing.organizationBillingData.costs)
      }
    : null;

export const tableOrganizationsBillingDataSelector = (
  state: RootReducer
): TableOrganizationBillingData[] | null =>
  state.billing.userBillingData?.organizations.map((organization) => ({
    id: organization.id,
    name: organization.name,
    status: ORG_STATUSES_LABELS[organization.status],
    ...formatCosts(organization.costs)
  })) || null;

export const tableOrganizationsTotalBillingDataSelector = (
  state: RootReducer
): TableTotalOrganizationBillingData | null =>
  state.billing.userBillingData &&
  state.billing.userBillingData.organizations.length > 0
    ? {
        id: "__total",
        name: "Total",
        status: "",
        ...formatCosts(state.billing.userBillingData.costs)
      }
    : null;

export const organizationBillingDataSelector = (
  state: RootReducer
): OrganizationBillingData | null => state.billing.organizationBillingData;

export const isOrganizationBillingDataLoadingSelector = (
  state: RootReducer
): boolean => state.billing.isOrganizationBillingDataLoading;

export const userBillingDataSelector = (
  state: RootReducer
): UserBillingData | null => state.billing.userBillingData;

export const isUserBillingDataLoadingSelector = (state: RootReducer): boolean =>
  state.billing.isUserBillingDataLoading;

export const isProjectBillingInstancesDataLoadingSelector = (
  state: RootReducer
): boolean => state.billing.isProjectBillingInstancesDataLoading;

export const isProjectBillingVolumesDataLoadingSelector = (
  state: RootReducer
): boolean => state.billing.isProjectBillingVolumesDataLoading;

export const isProjectBillingDatabasesDataLoadingSelector = (
  state: RootReducer
): boolean => state.billing.isProjectBillingDatabasesDataLoading;

export const projectBillingInstancesDataSelector = (
  state: RootReducer
): ProjectBillingInstancesData | null =>
  state.billing.projectBillingInstancesData;

export const tableProjectBillingInstancesRecordsSelector = (
  state: RootReducer
): TableBillingInstancesRecord[] | null =>
  state.billing.projectBillingInstancesData?.items.map((record) => ({
    id: record.id,
    type: record.type,
    name: record.name,
    status: record.deleted ? "deleted" : "available",
    deleted: record.deleted
      ? formatDate(new Date(record.deleted), DATE_FORMATS.DATETIME)
      : "",
    itemFlavors: record.itemFlavors.map((flavor) => ({
      name: flavor.name,
      description: flavor.description,
      ...formatCosts(flavor.costs)
    })),
    ...formatCosts(record.costs)
  })) || null;

export const tableProjectBillingInstancesRecordsTotalSelector = (
  state: RootReducer
): TableTotalBillingInstancesRecord | null =>
  state.billing.projectBillingInstancesData &&
  state.billing.projectBillingInstancesData.items.length > 0
    ? {
        id: "Total",
        name: "",
        deleted: "",
        itemFlavors: "",
        status: "",
        type: "",
        ...formatCosts(state.billing.projectBillingInstancesData.costs)
      }
    : null;

// volumes tab
export const projectBillingVolumesDataSelector = (
  state: RootReducer
): ProjectBillingVolumesData | null => state.billing.projectBillingVolumesData;

export const tableProjectBillingVolumesRecordsSelector = (
  state: RootReducer
): TableBillingVolumesRecord[] | null =>
  state.billing.projectBillingVolumesData?.items.map((record) => ({
    id: record.id,
    name: record.name ? record.name : "",
    type: record.type,
    deleted: record.deleted, // "true" or "false"
    status: record.deleted === "true" ? "deleted" : "available",
    itemFlavors: record.itemFlavors.map((flavor) => ({
      name: flavor.name,
      description: flavor.description,
      ...formatCosts(flavor.costs)
    })),
    ...formatCosts(record.costs)
  })) || null;

export const tableProjectBillingVolumesRecordsTotalSelector = (
  state: RootReducer
): TableTotalBillingVolumesRecord | null =>
  state.billing.projectBillingVolumesData &&
  state.billing.projectBillingVolumesData.items.length > 0
    ? {
        id: "Total",
        deleted: "",
        status: "",
        name: "",
        itemFlavors: "",
        type: "",
        ...formatCosts(state.billing.projectBillingVolumesData.costs)
      }
    : null;

// databases tab
export const projectBillingDatabasesDataSelector = (
  state: RootReducer
): ProjectBillingDatabasesData | null =>
  state.billing.projectBillingDatabasesData;

export const tableProjectBillingDatabasesRecordsSelector = (
  state: RootReducer
): TableBillingDatabasesRecord[] | null =>
  state.billing.projectBillingDatabasesData?.items.map((record) => ({
    id: record.id,
    name: record.name,
    type: record.type,
    status: record.deleted === "true" ? "deleted" : "available",
    deleted: record.deleted,
    itemFlavors: record.itemFlavors.map((flavor) => ({
      name: flavor.name,
      ...formatCosts(flavor.costs)
    })),
    ...formatCosts(record.costs)
  })) || null;

export const tableProjectBillingDatabasesRecordsTotalSelector = (
  state: RootReducer
): TableTotalBillingDatabasesRecord | null =>
  state.billing.projectBillingDatabasesData &&
  state.billing.projectBillingDatabasesData.items.length > 0
    ? {
        id: "Total",
        name: "",
        deleted: "",
        status: "",
        itemFlavors: "",
        type: "",
        ...formatCosts(state.billing.projectBillingDatabasesData.costs)
        // ...Object.keys(state.billing.projectBillingDatabasesData.costs).reduce(
        //   (acc, cur) => ({
        //     ...acc,
        //     [`cost-${cur}`]:
        //       state.billing.projectBillingDatabasesData?.costs[cur].toFixed(2)
        //   }),
        //   {}
        // )
      }
    : null;

// summary tab

const getInstancesCosts = (state: RootReducer) =>
  state.billing.projectBillingInstancesData?.costs;
const getVolumesCosts = (state: RootReducer) =>
  state.billing.projectBillingVolumesData?.costs;
const getDatabasesCosts = (state: RootReducer) =>
  state.billing.projectBillingDatabasesData?.costs;

export const tableProjectBillingSummaryRecordsSelector = createSelector(
  [getInstancesCosts, getVolumesCosts, getDatabasesCosts],
  (instancesCosts, volumesCosts, databasesCosts) => {
    return [
      {
        id: BILLING_RESOURCES_TYPES_TITLES[BILLING_RESOURCES_TYPES.INSTANCES],
        ...formatCosts(instancesCosts)
      },
      {
        id: BILLING_RESOURCES_TYPES_TITLES[BILLING_RESOURCES_TYPES.VOLUMES],
        ...formatCosts(volumesCosts)
      },
      {
        id: BILLING_RESOURCES_TYPES_TITLES[BILLING_RESOURCES_TYPES.DATABASES],
        ...formatCosts(databasesCosts)
        // Uncomment and update this part if you need to add collapsed data to the summary tab
        // items: state.billing.projectBillingDatabasesData?.items.map((item) => ({
        //   id: item.id,
        //   ...formatCosts(item.costs)
        // })),
      }
    ];
  }
);

export const tableProjectBillingSummaryRecordsTotalSelector = createSelector(
  [getInstancesCosts, getVolumesCosts, getDatabasesCosts],
  (instancesCosts, volumesCosts, databasesCosts) => {
    const totalCosts = { chf: 0, eur: 0 };

    [instancesCosts, volumesCosts, databasesCosts].forEach((costs) => {
      if (costs) {
        Object.keys(totalCosts).forEach((currency) => {
          totalCosts[currency] += costs[currency] || 0;
        });
      }
    });

    return {
      id: "Total",
      ...formatCosts(totalCosts)
    };
  }
);

import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import { StaticTimePicker, StaticTimePickerProps } from "@mui/x-date-pickers";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

type StyledStaticTimePickerProps = {
  content?: string;
} & StaticTimePickerProps<unknown>;

export const StyledStaticTimePicker = styled(
  StaticTimePicker
)<StyledStaticTimePickerProps>`
  background: inherit;

  & .MuiTypography-root.MuiTypography-overline {
    display: none;
  }
  &:after {
    content: ${({ content }) => (content ? `"${content}"` : "Select Time")};
    display: inline;
    text-align: center;
    font-size: larger;
    margin-top: 5%;
    margin-bottom: -10%;
    color: ${({ theme }) =>
      theme.palette.mode === "dark"
        ? theme.palette.text.primary
        : theme.palette.text.secondary};
  }
`;

export const Dialog = styled(MuiDialog)`
  & .MuiDialog-paper {
    width: 600px;
  }
`;

export const FormDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
`;

export const StepArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(0, 1)};
`;

export const StepButton = styled(IconButton)`
  padding: 0;
`;

export const ToggleButtonGroupStyled = styled(ToggleButtonGroup)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  /* use when you have long list of buttons */
  display: flex;
  flex-wrap: wrap;
  flex-basis: auto;
`;

export const ToggleButtonStyled = styled(ToggleButton)`
  flex: 1 1 0;
  width: fit-content;
  /* max-width: "fit-content" for making small buttons */
`;

export const ArrowUpIcon = styled(ArrowUpward)`
  font-size: small;
`;

export const ArrowDownIcon = styled(ArrowDownward)`
  font-size: small;
`;

export const LabelFieldText = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(0)};
  color: ${({ theme }) => theme.palette.primary.dark};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  /* font-size: smaller; */
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const LoaderContainer = styled.div`
  height: ${({ theme }) => theme.spacing(9)};
`;

export const UserNotesContainer = styled(Typography)`
  white-space: pre-line;
  margin-top: ${({ theme }) => theme.spacing(0)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  margin-left: ${({ theme }) => theme.spacing(0.3)};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  font-size: smaller;
  line-height: 0.9;
  min-height: 0.9em;
`;

// export const DatePickerTextField = styled(TextField)`
//   margin: ${({ theme }) => theme.spacing(3, 2, 1, 1.3)};
// `;

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  & > * {
    transform: scale(0.75);
    margin: -5%;
  }
`;

export const DatePickerContainer = styled.div`
  display: flex;
`;

export const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(0)};
`;

export const DividerText = styled(Typography)`
  /* color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.grey[900]
      : theme.palette.grey[100]}; */
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.primary.main};
  font-weight: 450;
  font-size: xx-small;
`;

export const StyledDivider = styled.hr`
  flex-grow: 1;
  border: none;
  height: 1px;
  background: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.grey[900]
      : theme.palette.grey[100]};
`;

export const ToggleLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 450;
  font-size: smaller;
`;

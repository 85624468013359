import Button from "@mui/material/Button";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { CustomSelect } from "components/common/CustomSelect";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import { adminRoleSelector } from "modules/auth/selectors";
import * as enterprisesActions from "modules/enterprises/actions";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectsSelector } from "modules/projects/selectors";
import { Project } from "modules/projects/types";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { getProjectRegion } from "utils/getProjectRegion";
import { getSelectOption } from "utils/getSelectOption";
import { validateName } from "utils/validateName";
import { string } from "yup";
import { appConfig } from "../../appConfig";
import {
  ADMIN_ROLES,
  ERROR_MESSAGES,
  PROJECT_NAME_LENGTH,
  REGEX,
  ROUTES
} from "../../constants";
import { DIALOG_TYPES } from "./types";

const POLL_ID_PREFIX = "PROJECTS";

const POLL_IDS = {
  projects: "PROJECTS"
};

const title = "Projects";

const breadcrumbs: Breadcrumb[] = [{ text: "Projects", url: ROUTES.PROJECTS }];

const tableColumns: TableColumn<Project>[] = [
  { key: "name", label: "Project Name" },
  { key: "id", label: "Project ID" },
  { key: "region", label: "Region" },
  { key: "org_id", label: "Organization ID" },
  { key: "org_name", label: "Organization Name" }
];

export const Projects: FC = () => {
  const dispatch = useDispatch();
  const adminRole = useSelector(adminRoleSelector);
  const isUserManagementAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.USER_MANAGEMENT)
  );
  const isOperatorRoleAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.OPERATOR)
  );

  const projects = useSelector(projectsSelector);
  // const projectQuotas = useSelector(projectQuotasSelector);
  // const areProjectQuotasLoading = useSelector(areProjectQuotasLoadingSelector);

  const allRegionsAsOptions = (
    appConfig.availableRegions
      ? [
          ...appConfig.availableRegions.map((region) => ({
            name: region,
            id: region
          }))
        ]
      : []
  ).map((region) => getSelectOption(region, "name", "id"));

  const [showOnly, setShowOnly] = useState<string>(
    allRegionsAsOptions[0].value
  );

  const handleChangeFilter = useCallback((value: string) => {
    setShowOnly(value);
  }, []);

  const generateTableItemURL = useCallback(
    (id: string) => {
      if (projects) {
        return generatePath(ROUTES.PROJECT, {
          region: getProjectRegion(projects, id),
          projectId: id
        });
      }
    },
    [projects]
  );

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const selectedProjectRegion = useMemo(() => {
    if (projects && selectedItemId) {
      return getProjectRegion(projects, selectedItemId);
    }
  }, [projects, selectedItemId]);

  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE, isOpened: false });

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const handleDialogOpen = useCallback(
    (dialogType: DIALOG_TYPES, id?: string) => {
      if (id) setSelectedItemId(id);
      setDialog({
        type: dialogType,
        isOpened: true
      });
    },
    []
  );

  const tableActions: TableRowActionsMenuItem<Project>[] = [
    // {
    //   label: "Set Quotas",
    //   handler: (id) => handleDialogOpen(DIALOG_TYPES.SET_QUOTAS, id)
    // },
    // {
    //   label: "Edit",
    //   handler: (id) => handleDialogOpen(DIALOG_TYPES.EDIT, id),
    //   isDisabled: () => true
    // },
    {
      label: "Delete",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.DELETE, id),
      isDisabled: () => true
    }
  ];

  const handleConfirmCreateProject = useCallback(
    (data: { project_name: string; org_id: string; region: SelectOption }) => {
      dispatch(
        projectsActions.createProject.started({
          name: data.project_name,
          organizationId: data.org_id,
          region: data.region.value
        })
      );
      handleCloseDialog();
    },
    [dispatch, handleCloseDialog]
  );

  // const handleConfirmEditProject = useCallback(
  //   (data: { name: string }) => {
  //     if (selectedItemId) {
  //       console.log(selectedItemId);
  //     }
  //     handleCloseDialog();
  //   },
  //   [selectedItemId, handleCloseDialog]
  // );

  // const handleConfirmSetQuotasProject = useCallback(
  //   (data: {
  //     vm_limit: number;
  //     vm_total_vCPUs: number;
  //     vm_total_memory: number;
  //     volumes_limit: number;
  //     volume_snapshots_limit: number;
  //     volume_storage_limit: number;
  //     networks_limit: number;
  //     firewalls_limit: number;
  //     firewall_rules_limit: number;
  //   }) => {
  //     if (selectedItemId && selectedProjectRegion) {
  //       dispatch(
  //         projectsActions.setProjectQuotas.started({
  //           id: selectedItemId,
  //           region: selectedProjectRegion,
  //           data: {
  //             instances: data.vm_limit,
  //             cores: data.vm_total_vCPUs,
  //             ram: data.vm_total_memory * 1024,
  //             volumes: data.volumes_limit,
  //             snapshots: data.volume_snapshots_limit,
  //             gigabytes: data.volume_storage_limit,
  //             network: data.networks_limit,
  //             security_group: data.firewalls_limit,
  //             security_group_rule: data.firewall_rules_limit
  //           }
  //         })
  //       );
  //     }
  //     handleCloseDialog();
  //   },
  //   [selectedItemId, selectedProjectRegion, handleCloseDialog, dispatch]
  // );

  const handleConfirmDeleteProject = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        enterprisesActions.deleteOrganization.started({
          id: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [dispatch, selectedItemId, handleCloseDialog]);

  const previousSelectedItemId = usePrevious(selectedItemId);
  const currentItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const currentProjectName = projects?.find(
    (project) => project.id === currentItemId
  )?.name;

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE]: {
      onConfirm: handleConfirmCreateProject,
      title: "Create project",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "project_name",
          type: FIELD_TYPES.TEXT,
          label: "Project Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(PROJECT_NAME_LENGTH as number)
            })
            .matches(REGEX.PROJECT_NAME, ERROR_MESSAGES.PROJECT_NAME)
        },
        {
          name: "org_id",
          type: FIELD_TYPES.TEXT,
          label: "Organization ID",
          rules: string().required()
        },
        {
          name: "region",
          type: FIELD_TYPES.SELECT,
          label: "Region",
          options: allRegionsAsOptions,
          rules: selectOptionSchema
        }
      ]
    },
    // [DIALOG_TYPES.EDIT]: {
    //   onConfirm: handleConfirmEditProject,
    //   title: "Edit project",
    //   confirmButtonLabel: "Save",
    //   fields: [
    //     {
    //       name: "name",
    //       type: FIELD_TYPES.TEXT,
    //       label: "Name",
    //       defaultValue:
    //         projects?.find((project) => project.id === selectedItemId)?.name ||
    //         "",
    //       rules: string()
    //         .required()
    //         .test({
    //           name: "validateName",
    //           test: validateName(PROJECT_NAME_LENGTH as number)
    //         })
    //         .matches(REGEX.PROJECT_NAME, ERROR_MESSAGES.PROJECT_NAME)
    //     }
    //   ]
    // },
    // [DIALOG_TYPES.SET_QUOTAS]: {
    //   onConfirm: handleConfirmSetQuotasProject,
    //   title: `Set quotas for "${currentProjectName ?? "selected"}" project?`,
    //   isLocked: !isUserManagementAllowed,
    //   fields: [
    //     {
    //       name: "vm_limit",
    //       type: FIELD_TYPES.NUMBER,
    //       label: "Virtual machines limit",
    //       defaultValue: projectQuotas?.instances.limit,
    //       min: 1,
    //       suffix: " vm ",
    //       rules: number().integer().required()
    //     },
    //     {
    //       name: "vm_total_vCPUs",
    //       type: FIELD_TYPES.NUMBER,
    //       label: "Virtual machines total vCPUs",
    //       defaultValue: projectQuotas?.cores.limit,
    //       min: 1,
    //       suffix: "vCPU",
    //       rules: number().integer().required()
    //     },
    //     {
    //       name: "vm_total_memory",
    //       type: FIELD_TYPES.NUMBER,
    //       label: "Virtual machines total memory",
    //       defaultValue: projectQuotas?.ram.limit
    //         ? projectQuotas.ram.limit / 1024
    //         : 0,
    //       min: 1,
    //       suffix: "GiB ",
    //       rules: number().integer().required()
    //     },
    //     {
    //       name: "volumes_limit",
    //       type: FIELD_TYPES.NUMBER,
    //       label: "Volumes limit",
    //       defaultValue: projectQuotas?.volumes.limit,
    //       min: 1,
    //       suffix: "vol",
    //       rules: number().integer().required()
    //     },
    //     {
    //       name: "volume_snapshots_limit",
    //       type: FIELD_TYPES.NUMBER,
    //       label: "Volume snapshots limit",
    //       defaultValue: projectQuotas?.snapshots.limit,
    //       min: 1,
    //       suffix: "snap",
    //       rules: number().integer().required()
    //     },
    //     {
    //       name: "volume_storage_limit",
    //       type: FIELD_TYPES.NUMBER,
    //       label: "Volume storage limit",
    //       defaultValue: projectQuotas?.gigabytes.limit,
    //       min: 1,
    //       suffix: "GiB",
    //       rules: number().integer().required()
    //     },
    //     {
    //       name: "networks_limit",
    //       type: FIELD_TYPES.NUMBER,
    //       label: "Networks limit",
    //       defaultValue: projectQuotas?.network.limit,
    //       min: 1,
    //       suffix: "net",
    //       rules: number().integer().required()
    //     },
    //     {
    //       name: "firewalls_limit",
    //       type: FIELD_TYPES.NUMBER,
    //       label: "Firewalls limit",
    //       defaultValue: projectQuotas?.security_group.limit,
    //       min: 1,
    //       suffix: "fw",
    //       rules: number().integer().required()
    //     },
    //     {
    //       name: "firewall_rules_limit",
    //       type: FIELD_TYPES.NUMBER,
    //       label: "Firewall rules limit",
    //       defaultValue: projectQuotas?.security_group_rule.limit,
    //       min: 1,
    //       suffix: "fw_r",
    //       rules: number().integer().required()
    //     }
    //   ],
    //   confirmButtonLabel: "Set Quotas"
    // },
    [DIALOG_TYPES.DELETE]: {
      onConfirm: handleConfirmDeleteProject,
      title: `Are you sure you want to delete "${
        currentProjectName ?? "selected"
      }" project?`,
      confirmButtonLabel: "Delete"
    }
  };

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.projects}`,
        action: projectsActions.getProjects.started({
          region: showOnly
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(projectsActions.clear());
  });

  useEffect(() => {
    dispatch(
      pollingActions.stopPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.projects}`
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.projects}`,
        action: projectsActions.getProjects.started({
          region: showOnly
        })
      })
    );
  }, [dispatch, showOnly, isUserManagementAllowed]);

  useEffect(() => {
    if (selectedItemId && selectedProjectRegion) {
      dispatch(
        projectsActions.getProjectQuotas.started({
          id: selectedItemId,
          region: selectedProjectRegion
        })
      );
    }
  }, [dispatch, selectedItemId, selectedProjectRegion]);

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Table
        title={title}
        isSearchEnabled={true}
        isSortingEnabled={true}
        actions={
          isUserManagementAllowed || isOperatorRoleAllowed
            ? tableActions
            : undefined
        }
        rows={projects || []}
        columns={tableColumns}
        itemLink={{
          column: "name",
          getURL: generateTableItemURL
        }}
        isLoading={!projects}
        toolbarItems={
          <>
            {/* <Tooltip title={"Under development"} placement={"bottom"} arrow> */}
            <span>
              <Button
                onClick={() => handleDialogOpen(DIALOG_TYPES.CREATE)}
                variant={"contained"}
                // disabled={true}
                disabled={!projects}
              >
                Create project
              </Button>
            </span>
            {/* </Tooltip> */}
            <CustomSelect
              label={"Show"}
              options={allRegionsAsOptions}
              defaultValue={allRegionsAsOptions[0].value}
              onChange={handleChangeFilter}
            />
          </>
        }
      />
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
    </>
  );
};

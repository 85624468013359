import { FormControl, MenuItem } from "@mui/material";

import styled from "styled-components";

export const ShowUsersItem = styled(MenuItem)`
  font-size: 0.9em;
`;

export const ShowUsersFormControl = styled(FormControl)`
  width: 220px;
  margin: ${({ theme }) => theme.spacing(0, 2, 0, 1.5)};
`;

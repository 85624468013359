import { watcherSaga as authSaga } from "modules/auth/sagas";
import { watcherSaga as billingSaga } from "modules/billing/sagas";
import { watcherSaga as enterprisesSaga } from "modules/enterprises/sagas";
import { watcherSaga as pollingSaga } from "modules/polling/sagas";
import { watcherSaga as projectsSaga } from "modules/projects/sagas";
import { watcherSaga as supportSaga } from "modules/support/sagas";
import { watcherSaga as usersSaga } from "modules/users/sagas";
import { all, AllEffect } from "redux-saga/effects";

export function* rootSaga(): Generator<AllEffect<unknown>, void, unknown> {
  yield all([
    authSaga(),
    pollingSaga(),
    billingSaga(),
    usersSaga(),
    supportSaga(),
    enterprisesSaga(),
    projectsSaga()
  ]);
}

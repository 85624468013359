import FailedIcon from "@mui/icons-material/ClearRounded";
import SuccessIcon from "@mui/icons-material/Done";
import { FormControlLabel, Switch, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { CustomSelect } from "components/common/CustomSelect";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  ORDERS,
  TABLE_SORTING_TYPES,
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useDebounce } from "hooks/useDebounce";
import { useMount } from "hooks/useMount";
import { useUnmount } from "hooks/useUnmount";
import { adminRoleSelector } from "modules/auth/selectors";
import * as billingActions from "modules/billing/actions";
import * as pollingActions from "modules/polling/actions";
import * as usersActions from "modules/users/actions";
import {
  areUsersLoadingSelector,
  isUserCreatingSelector,
  isUserInfoUpdatingSelector,
  isUserModifyingEnablingSelector,
  tableUsersSelector,
  usersTotalSelector
} from "modules/users/selectors";
import { TableUser } from "modules/users/types";
import {
  ChangeEvent,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { isNumber } from "typeGuards/isNumber";
import { formatDate } from "utils/formatDate";
import { generateSearchString } from "utils/generateSearchString";
import { getSelectOption } from "utils/getSelectOption";
import { array, number, ref, string } from "yup";
import { appConfig } from "../../appConfig";
import {
  ADMIN_ROLES,
  DATE_FORMATS,
  DEFAULT_DEBOUNCE_DELAY,
  DEFAULT_PAGINATION_LIMIT,
  DEFAULT_REGION,
  DEFAULT_TRIAL_DAYS,
  DEFAULT_USERS_TO_SHOW,
  ERROR_MESSAGES,
  MAX_PAGINATION_LIMIT,
  REGEX,
  ROUTES,
  USERS_FILTER_OPTIONS
} from "../../constants";
import { usePrevious } from "../../hooks/usePrevious";
import * as s from "./styles";
import { DIALOG_TYPES } from "./types";
const POLL_ID_PREFIX = "USERS";
const POLL_IDS = {
  users: "USERS"
};
const title = "Users";
const breadcrumbs: Breadcrumb[] = [{ text: "Users", url: ROUTES.USERS }];

export const Users: FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const adminRole = useSelector(adminRoleSelector);
  const isUserManagementAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.USER_MANAGEMENT)
  );

  const isBillingManagementAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.BILLING)
  );

  const isAdminRolesManagementAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.ADMIN_ROLES_MANAGEMENT)
  );
  const users = useSelector(tableUsersSelector);
  const areUsersLoading = useSelector(areUsersLoadingSelector);
  const usersTotal = useSelector(usersTotalSelector);
  const isUserCreating = useSelector(isUserCreatingSelector);
  const isUserInfoUpdating = useSelector(isUserInfoUpdatingSelector);
  const isUserModifyingEnabling = useSelector(isUserModifyingEnablingSelector);

  const isOperationInProgress =
    isUserCreating || isUserInfoUpdating || isUserModifyingEnabling;
  const previousIsOperationInProgress = usePrevious(isOperationInProgress);

  const queryParams = new URLSearchParams(location.search);
  const pageParam = Number(queryParams.get("page"));
  const [page, setPage] = useState<number>(
    Number.isInteger(pageParam) && pageParam > 0 ? pageParam - 1 : 0
  );
  const itemsParam = Number(queryParams.get("items"));
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    Number.isInteger(itemsParam) &&
      itemsParam > 0 &&
      itemsParam <= MAX_PAGINATION_LIMIT
      ? itemsParam
      : DEFAULT_PAGINATION_LIMIT
  );

  const sortParam = queryParams.get("sort");
  const [sortColumn, setSortColumn] = useState<string>(sortParam || "");

  const orderParam = queryParams.get("order");
  const [sortOrder, setSortOrder] = useState<string>(orderParam || "");

  const hideDisabledUsersParam = queryParams.get("hide-disabled");
  const [hideDisabledUsers, setHideDisabledUsers] = useState<boolean>(
    !!hideDisabledUsersParam || true
  );
  const handleChangeDisabledUsersFilter = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setHideDisabledUsers(checked);
    },
    []
  );

  const [showOnly, setShowOnly] = useState<string>(DEFAULT_USERS_TO_SHOW);
  const handleChangeFilter = useCallback((value: string) => {
    setShowOnly(value);
    setPage(0);
  }, []);

  const currentDate = new Date();
  const start_date = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const end_date = currentDate;

  const searchParams = queryParams.get("search");
  const [search, setSearch] = useState<string>(
    searchParams ? String(searchParams) : ""
  );
  const debouncedSearch = useDebounce(search, DEFAULT_DEBOUNCE_DELAY);

  const generateTableItemURL = useCallback(
    (id: string) => generatePath(ROUTES.USER, { userId: id }),
    []
  );

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE, isOpened: false });

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const handleDialogOpen = useCallback(
    (dialogType: DIALOG_TYPES, id?: string) => {
      if (id) setSelectedItemId(id);
      setDialog({
        type: dialogType,
        isOpened: true
      });
    },
    []
  );

  const tableColumns: TableColumn<TableUser>[] = [
    { key: "id", label: "ID" },
    { key: "email", label: "E-mail" },
    { key: "name", label: "Name" },
    { key: "user-type", label: "Type" },
    ...(showOnly === "admins"
      ? [
          {
            key: "admin-roles" as keyof TableUser,
            label: "Admin Roles"
          }
        ]
      : []),
    { key: "org-number-limit", label: "Org Quotas" },
    {
      key: "registration-date",
      label: "Registered",
      sortingType: TABLE_SORTING_TYPES.DATE
    },
    ...(showOnly === "trial"
      ? [
          {
            key: "expiration-date" as keyof TableUser,
            label: "Expiration Date",
            sortingType: TABLE_SORTING_TYPES.DATE
          }
        ]
      : []),
    { key: "otp-required", label: "2FA", width: "75px" },
    { key: "is-enabled", label: "Status", width: "80px" }
  ];

  const tableUsersActions: TableRowActionsMenuItem<TableUser>[] = [
    {
      label: "Set admin roles",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.SET_ADMIN_ROLES, id),
      isDisabled: () => !isAdminRolesManagementAllowed
    },
    {
      label: "Modify user type",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.MODIFY_USER_TYPE, id)
    },
    {
      label: "Modify user trial period",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.MODIFY_TRIAL_PERIOD, id),
      isDisabled: (user) => user["user-type"] !== "trial"
    },
    {
      label: "Change organizations quotas",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.CHANGE_QRG_QUOTAS, id)
    },
    {
      label: "Set password",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.SET_PASSWORD, id)
    },
    {
      label: "Disable user",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.DISABLE_USER, id),
      isDisabled: (user) => !user["is-enabled"]
    },
    {
      label: "Enable user",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.ENABLE_USER, id),
      isDisabled: (user) => user["is-enabled"]
    },
    {
      label: "Force to configure 2FA",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.ENABLE_2FA, id),
      isDisabled: (user) => user["otp-required"]
    },
    {
      label: "Erase 2FA settings",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.DISABLE_2FA, id),
      isDisabled: (user) => !user["otp-required"]
    }
  ];

  const handleConfirmCreateUser = useCallback(
    (data: {
      first_name: string;
      last_name: string;
      email: string;
      email_verified: boolean;
      creds_temporary: boolean;
      password: string;
      allowed_org_number: number;
      company_name: string;
      tax_number: string;
      country: string;
      locality: string;
      mobile: string;
      postal_code: string;
      region: string;
      street: string;
      is_otp_required: boolean;
      trial_days: number;
      user_type: SelectOption;
    }) => {
      dispatch(
        usersActions.createUser.started({
          data: {
            email: data.email,
            email_verified: data.email_verified,
            is_otp_required: data.is_otp_required,
            first_name: data.first_name,
            last_name: data.last_name,
            creds_temporary: data.creds_temporary,
            creds_password: data.password,
            trial_days: data.trial_days,
            attributes: {
              user_type: data.user_type.value,
              allowed_org_number: data.allowed_org_number,
              company_name: data.company_name,
              tax_number: data.tax_number,
              country: data.country,
              locality: data.locality,
              mobile: data.mobile,
              postal_code: data.postal_code,
              region: data.region,
              street: data.street
            }
          }
        })
      );
      handleCloseDialog();
    },
    [dispatch, handleCloseDialog]
  );

  const handleConfirmModifyUserType = useCallback(
    (data: { user_type: SelectOption; trial_days?: number }) => {
      if (selectedItemId) {
        dispatch(
          usersActions.updateUserInfo.started({
            id: selectedItemId,
            data: {
              trial_days: data.trial_days,
              attributes: { user_type: data.user_type.value }
            }
          })
        );
      }
      handleCloseDialog();
    },
    [dispatch, handleCloseDialog, selectedItemId]
  );

  const handleConfirmModifyTrialPeriod = useCallback(
    (data: { trial_days: number }) => {
      if (selectedItemId) {
        dispatch(
          usersActions.updateUserInfo.started({
            id: selectedItemId,
            data: { trial_days: data.trial_days }
          })
        );
      }
      handleCloseDialog();
    },
    [selectedItemId, handleCloseDialog, dispatch]
  );

  const handleConfirmSetPasswdButtonClick = useCallback(
    (data: {
      password: string;
      confirm_password: string;
      change_password_on_next_login: boolean;
    }) => {
      if (selectedItemId) {
        dispatch(
          usersActions.setUsersPassword.started({
            id: selectedItemId,
            creds_temporary: data.change_password_on_next_login,
            creds_password: data.password
          })
        );
      }
      handleCloseDialog();
    },
    [selectedItemId, handleCloseDialog, dispatch]
  );

  const handleConfirmDisableUserButtonClick = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        usersActions.modifyUserEnabling.started({
          id: selectedItemId,
          enabling_status: "false"
        })
      );
    }
    handleCloseDialog();
  }, [selectedItemId, handleCloseDialog, dispatch]);

  const handleConfirmEnableUserButtonClick = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        usersActions.modifyUserEnabling.started({
          id: selectedItemId,
          enabling_status: "true"
        })
      );
    }
    handleCloseDialog();
  }, [selectedItemId, handleCloseDialog, dispatch]);

  const handleConfirmSetAdminRoles = useCallback(
    (data: { admin_roles: SelectOption[] }) => {
      if (selectedItemId) {
        dispatch(
          usersActions.updateAdminRoles.started({
            id: selectedItemId,
            data: {
              attributes: {
                admin_roles: data.admin_roles.map((role) => role.value)
              }
            }
          })
        );
      }
      handleCloseDialog();
    },
    [dispatch, handleCloseDialog, selectedItemId]
  );

  const handleConfirmChangeOrgQuotas = useCallback(
    (data: { allowed_org_number: number }) => {
      if (selectedItemId) {
        dispatch(
          usersActions.updateUserInfo.started({
            id: selectedItemId,
            data: {
              attributes: {
                allowed_org_number: data.allowed_org_number
              }
            }
          })
        );
      }
      handleCloseDialog();
    },
    [dispatch, handleCloseDialog, selectedItemId]
  );

  const handleConfirmEnable2FA = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        usersActions.updateUserInfo.started({
          id: selectedItemId,
          data: {
            is_otp_required: true
          }
        })
      );
    }
    handleCloseDialog();
  }, [dispatch, handleCloseDialog, selectedItemId]);

  const handleConfirmDisable2FA = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        usersActions.updateUserInfo.started({
          id: selectedItemId,
          data: {
            is_otp_required: false
          }
        })
      );
    }
    handleCloseDialog();
  }, [dispatch, handleCloseDialog, selectedItemId]);

  const handleConfirmGenerateButtonClick = useCallback(
    (data: { region: SelectOption; start_date: Date; end_date: Date }) => {
      dispatch(
        billingActions.generateBillingDataReport.started({
          startDate: formatDate(data.start_date, DATE_FORMATS.ISO_DATE),
          endDate: formatDate(data.end_date, DATE_FORMATS.ISO_DATE),
          region: data.region.value === "all" ? "" : data.region.value
        })
      );
      handleCloseDialog();
    },
    [dispatch, handleCloseDialog]
  );

  const handleSortChange = useCallback((column: string, order: ORDERS) => {
    setSortColumn(column);
    setSortOrder(order);
  }, []);

  const handleChangePage = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    []
  );

  const handleChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(Number(event.target.value));
      setPage(0);
    },
    []
  );

  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const regionsOptions = (
    appConfig.availableRegions
      ? [
          DEFAULT_REGION,
          ...appConfig.availableRegions.map((region) => ({
            name: region,
            id: region
          }))
        ]
      : []
  ).map((region) => getSelectOption(region, "name", "id"));

  const userTypesOptions = (
    appConfig.userTypes
      ? [
          ...appConfig.userTypes.split(";").map((type) => ({
            label: type,
            value: type
          }))
        ]
      : []
  ).map((type) => getSelectOption(type, "label", "value"));

  const adminRolesAsSelectionOption = Object.values(ADMIN_ROLES)
    .map((role) => ({
      label: role,
      value: role
    }))
    .map((role) => getSelectOption(role, "label", "value"));

  const currentAdminRoles = users?.find(
    (user) => user?.id === selectedItemId
  )?.["admin-roles"];

  const currentAdminRolesAsSelectionOption = adminRolesAsSelectionOption.filter(
    (role) => currentAdminRoles?.includes(role.value)
  );

  const previousSelectedItemId = usePrevious(selectedItemId);
  const currentItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const currentUserName = users?.find(
    (user) => user.id === currentItemId
  )?.name;

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE]: {
      onConfirm: handleConfirmCreateUser,
      title: "Create user",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "user_type",
          type: FIELD_TYPES.SELECT,
          label: "Select User Type",
          options: userTypesOptions,
          defaultValue: userTypesOptions ? userTypesOptions[0] : null,
          rules: selectOptionSchema.required()
        },
        {
          name: "trial_days",
          type: FIELD_TYPES.NUMBER,
          label: "Trial Days",
          min: 0,
          max: 365,
          defaultValue: DEFAULT_TRIAL_DAYS,
          rules: number()
            .integer()
            .min(0, ERROR_MESSAGES.TRIAL_DAYS)
            .max(365, ERROR_MESSAGES.TRIAL_DAYS),
          isHidden: (fieldValues) => {
            return (
              !fieldValues.user_type ||
              (fieldValues.user_type &&
                (fieldValues.user_type as SelectOption).value !== "trial")
            );
          }
        },
        // {
        //   name: "date_and_number_field",
        //   type: FIELD_TYPES.DATE_AND_NUMBER,
        //   label: "Select Date and Number of Days",
        //   labelPicker: "Choose Date",
        //   min: 1,
        //   defaultValue: {
        //     number: 30,
        //     date: new Date()
        //   },
        //   isHidden: (fieldValues) => {
        //     return (
        //       ((fieldValues.user_type as SelectOption).value as USER_TYPES) !==
        //       USER_TYPES.TRIAL
        //     );
        //   }
        // rules: {
        //   date: date().required("Date is required"),
        //   number: number()
        //     .integer()
        //     .required()
        //     .min(1, "Number must be at least 1")
        // }
        // },
        {
          name: "email",
          type: FIELD_TYPES.TEXT,
          label: "E-mail address",
          rules: string()
            .required()
            .matches(REGEX.EMAIL_ADDRESS, ERROR_MESSAGES.EMAIL_ADDRESS)
        },
        {
          name: "email_verified",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: true,
          label: "Email verified"
        },
        {
          name: "creds_temporary",
          type: FIELD_TYPES.TOGGLE,
          label: "Change password on next login"
        },
        {
          name: "is_otp_required",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: true,
          label: "2FA"
        },
        {
          name: "password",
          type: FIELD_TYPES.PASSWORD,
          label: "Password",
          autocomplete: "new-password",
          rules: string()
            .required()
            .matches(REGEX.PASSWORD, ERROR_MESSAGES.PASSWORD)
        },
        {
          name: "confirm_password",
          type: FIELD_TYPES.PASSWORD,
          label: "Confirm password",
          autocomplete: "new-password",
          rules: string().oneOf(
            [ref("password")],
            ERROR_MESSAGES.PASSWORDS_MUST_MATCH
          )
        },
        {
          name: "divider",
          type: FIELD_TYPES.DIVIDER,
          label: "Personal Information"
        },
        {
          name: "first_name",
          type: FIELD_TYPES.TEXT,
          label: "First name",
          rules: string().required()
        },
        {
          name: "last_name",
          type: FIELD_TYPES.TEXT,
          label: "Last name",
          rules: string().required()
        },
        {
          name: "mobile",
          type: FIELD_TYPES.TEXT,
          label: "Mobile Number",
          rules: string()
        },
        {
          name: "street",
          type: FIELD_TYPES.TEXT,
          label: "Street",
          rules: string()
        },
        {
          name: "locality",
          type: FIELD_TYPES.TEXT,
          label: "City or Locality",
          rules: string()
        },
        {
          name: "region",
          type: FIELD_TYPES.TEXT,
          label: "State, Province, or Region",
          rules: string()
        },
        {
          name: "country",
          type: FIELD_TYPES.TEXT,
          label: "Country",
          rules: string()
        },
        {
          name: "postal_code",
          type: FIELD_TYPES.TEXT,
          label: "Zip or Postal code",
          rules: string()
        },
        {
          name: "company_name",
          type: FIELD_TYPES.TEXT,
          label: "Company name",
          rules: string()
        },
        {
          name: "tax_number",
          type: FIELD_TYPES.TEXT,
          label: "Company Tax number",
          rules: string()
        },
        {
          name: "divider_close",
          type: FIELD_TYPES.DIVIDER,
          label: ""
        },
        {
          name: "allowed_org_number",
          type: FIELD_TYPES.NUMBER,
          label: "Organizations number limit",
          min: appConfig.defaultAllowedOrgNumber,
          defaultValue: appConfig.defaultAllowedOrgNumber,
          rules: number()
            .integer()
            .required()
            .min(
              appConfig.defaultAllowedOrgNumber,
              ERROR_MESSAGES.ORGANIZATIONS_NUMBER
            )
        }
      ]
    },
    [DIALOG_TYPES.MODIFY_USER_TYPE]: {
      onConfirm: handleConfirmModifyUserType,
      title: "Modify user type",
      confirmButtonLabel: "Modify",
      fields: [
        {
          name: "user_type",
          type: FIELD_TYPES.SELECT,
          label: "User Type",
          defaultValue: userTypesOptions.find(
            (option) =>
              option.value ===
              users?.find((user) => user.id === selectedItemId)?.["user-type"]
          ),
          options: userTypesOptions,
          rules: selectOptionSchema.required()
        },
        {
          name: "trial_days",
          type: FIELD_TYPES.NUMBER,
          label: "Trial Days",
          min: 0,
          max: 365,
          suffix: "day",
          defaultValue: () => {
            const trialDays = users?.find(
              (user) => user?.id === selectedItemId
            )?.["trial-days"];
            return trialDays && trialDays >= 0 ? trialDays : 0;
          },
          rules: number()
            .integer()
            .min(0, ERROR_MESSAGES.TRIAL_DAYS)
            .max(365, ERROR_MESSAGES.TRIAL_DAYS),
          isHidden: (fieldValues) => {
            return (
              !fieldValues.user_type ||
              (fieldValues.user_type &&
                (fieldValues.user_type as SelectOption).value !== "trial")
            );
          }
        }
      ]
    },
    [DIALOG_TYPES.MODIFY_TRIAL_PERIOD]: {
      onConfirm: handleConfirmModifyTrialPeriod,
      title: "Modify user trial period",
      confirmButtonLabel: "Modify",
      fields: [
        {
          name: "trial_days",
          type: FIELD_TYPES.NUMBER,
          label: "Trial Days",
          min: 0,
          max: 365,
          suffix: "day",
          defaultValue: () => {
            const trialDays = users?.find(
              (user) => user?.id === selectedItemId
            )?.["trial-days"];
            return trialDays && trialDays >= 0 ? trialDays : 0;
          },
          rules: number()
            .integer()
            .required()
            .min(0, ERROR_MESSAGES.TRIAL_DAYS)
            .max(365, ERROR_MESSAGES.TRIAL_DAYS)
        }
      ]
    },
    [DIALOG_TYPES.ENABLE_2FA]: {
      onConfirm: handleConfirmEnable2FA,
      title: `Are you sure you want to force "${
        currentUserName ?? "selected"
      }" user to configure 2FA?`,
      confirmButtonLabel: "Force"
    },
    [DIALOG_TYPES.DISABLE_2FA]: {
      onConfirm: handleConfirmDisable2FA,
      title: `Are you sure you want to erase 2FA settings for the "${
        currentUserName ?? "selected"
      }" user?`,
      confirmButtonLabel: "Erase"
    },
    [DIALOG_TYPES.SET_PASSWORD]: {
      onConfirm: handleConfirmSetPasswdButtonClick,
      title: "Set password to selected user",
      confirmButtonLabel: "Set",
      fields: [
        {
          name: "password",
          type: FIELD_TYPES.PASSWORD,
          label: "Password",
          autocomplete: "new-password",
          rules: string()
            .required()
            .matches(REGEX.PASSWORD, ERROR_MESSAGES.PASSWORD)
        },
        {
          name: "confirm_password",
          type: FIELD_TYPES.PASSWORD,
          label: "Confirm password",
          autocomplete: "new-password",
          rules: string().oneOf(
            [ref("password")],
            ERROR_MESSAGES.PASSWORDS_MUST_MATCH
          )
        },
        {
          name: "change_password_on_next_login",
          type: FIELD_TYPES.CHECKBOX,
          label: "Change password on next login",
          helperText: "Change password on next login"
        }
      ]
    },
    [DIALOG_TYPES.SET_ADMIN_ROLES]: {
      onConfirm: handleConfirmSetAdminRoles,
      title: "Set admin roles",
      confirmButtonLabel: "Set",
      isLocked: false,
      fields: [
        {
          name: "admin_roles",
          type: FIELD_TYPES.MULTISELECT,
          label: "Admin Roles",
          options: adminRolesAsSelectionOption,
          defaultValue: currentAdminRolesAsSelectionOption || [],
          rules: array(selectOptionSchema)
        },
        {
          name: "info",
          type: FIELD_TYPES.NOTES,
          label: `✍ Allowed roles: user_management, billing, operator, observe, admin_roles_management`
        }
      ]
    },
    [DIALOG_TYPES.CHANGE_QRG_QUOTAS]: {
      onConfirm: handleConfirmChangeOrgQuotas,
      title: "Change organizations quotas",
      confirmButtonLabel: "Edit",
      fields: [
        {
          name: "allowed_org_number",
          type: FIELD_TYPES.NUMBER,
          label: "Organizations number limit",
          min: appConfig.defaultAllowedOrgNumber,
          defaultValue:
            users?.find((user) => user?.id === selectedItemId)?.[
              "org-number-limit"
            ] || appConfig.defaultAllowedOrgNumber,
          rules: number()
            .integer()
            .required()
            .min(
              appConfig.defaultAllowedOrgNumber,
              ERROR_MESSAGES.ORGANIZATIONS_NUMBER
            )
        }
      ]
    },
    [DIALOG_TYPES.GENERATE_BILLING_REPORT]: {
      onConfirm: handleConfirmGenerateButtonClick,
      title: "Generate billing report",
      confirmButtonLabel: "Generate",
      fields: [
        {
          name: "region",
          type: FIELD_TYPES.SELECT,
          label: "Region",
          variant: "outlined",
          options: regionsOptions,
          defaultValue: regionsOptions
            ? regionsOptions.find((region) => region.value === "all")
            : null,
          rules: selectOptionSchema
        },

        {
          name: "start_date",
          type: FIELD_TYPES.DATE,
          labelPicker: "Start-date",
          defaultValue: start_date
        },
        {
          name: "end_date",
          type: FIELD_TYPES.DATE,
          labelPicker: "End-date",
          defaultValue: end_date
        },
        {
          name: "notes",
          type: FIELD_TYPES.NOTES,
          label: `Billing data is available for previous 12 months`
        },
        {
          name: "info",
          type: FIELD_TYPES.LABEL,
          label: `Report will be sent to your account email.`
        }
      ]
    },
    [DIALOG_TYPES.DISABLE_USER]: {
      onConfirm: handleConfirmDisableUserButtonClick,
      title: `Are you sure you want to disable "${
        currentUserName ?? "selected"
      }" user?`,
      confirmButtonLabel: "Disable"
    },
    [DIALOG_TYPES.ENABLE_USER]: {
      onConfirm: handleConfirmEnableUserButtonClick,
      title: `Are you sure you want to enable "${
        currentUserName ?? "selected"
      }" user?`,
      confirmButtonLabel: "Enable"
    }
  };

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.users}`,
        action: usersActions.getUsers.started({
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          show: showOnly,
          ...(hideDisabledUsers ? { "hide-disabled": hideDisabledUsers } : {}),
          ...(sortColumn ? { sort: sortColumn } : {}),
          ...(sortOrder ? { order: sortOrder } : {}),
          ...(debouncedSearch ? { search: debouncedSearch } : {})
        })
      })
    );
  });

  useEffect(() => {
    if (isNumber(usersTotal) && rowsPerPage * page > usersTotal) {
      setPage(0);
    }
  }, [usersTotal, rowsPerPage, page]);

  // useEffect(() => {
  //   setSortColumn("");
  //   setSortOrder("");
  // }, [showOnly]);

  useEffect(() => {
    setSortColumn("");
    setSortOrder("");
  }, []);

  useEffect(() => {
    history({
      search: generateSearchString({
        page: String(page + 1),
        items: String(rowsPerPage),
        show: String(showOnly),
        ...(hideDisabledUsers
          ? { "hide-disabled": String(hideDisabledUsers) }
          : {}),
        ...(sortColumn ? { sort: sortColumn } : {}),
        ...(sortOrder ? { order: sortOrder } : {}),
        ...(debouncedSearch ? { search: debouncedSearch } : {})
      })
    });
    dispatch(
      pollingActions.stopPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.users}`
      })
    );
    dispatch(
      usersActions.getUsers.started({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        show: showOnly,
        ...(hideDisabledUsers ? { "hide-disabled": hideDisabledUsers } : {}),
        ...(sortColumn ? { sort: sortColumn } : {}),
        ...(sortOrder ? { order: sortOrder } : {}),
        ...(debouncedSearch ? { search: debouncedSearch } : {})
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.users}`,
        action: usersActions.getUsers.started({
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          show: showOnly,
          ...(hideDisabledUsers ? { "hide-disabled": hideDisabledUsers } : {}),
          ...(sortColumn ? { sort: sortColumn } : {}),
          ...(sortOrder ? { order: sortOrder } : {}),
          ...(debouncedSearch ? { search: debouncedSearch } : {})
        })
      })
    );
  }, [
    dispatch,
    page,
    rowsPerPage,
    history,
    hideDisabledUsers,
    showOnly,
    debouncedSearch,
    sortColumn,
    sortOrder
  ]);

  useEffect(() => {
    if (previousIsOperationInProgress && !isOperationInProgress) {
      dispatch(
        usersActions.getUsers.started({
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          show: showOnly,
          ...(hideDisabledUsers ? { "hide-disabled": hideDisabledUsers } : {}),
          ...(sortColumn ? { sort: sortColumn } : {}),
          ...(sortOrder ? { order: sortOrder } : {}),
          ...(debouncedSearch ? { search: debouncedSearch } : {})
        })
      );
    }
  }, [
    isUserCreating,
    dispatch,
    page,
    rowsPerPage,
    hideDisabledUsers,
    showOnly,
    debouncedSearch,
    previousIsOperationInProgress,
    isOperationInProgress,
    sortColumn,
    sortOrder
  ]);

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(billingActions.clear());
    dispatch(usersActions.clear());
  });

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Table
        title={title}
        rows={users || []}
        columns={tableColumns}
        actions={isUserManagementAllowed ? tableUsersActions : undefined}
        itemLink={{
          column: "id",
          getURL: generateTableItemURL
        }}
        itemWithIcons={[
          {
            withText: false,
            column: "expiration-date",
            getIcon: (row) => {
              if (
                row["trial-days"] != undefined &&
                row["expiration-date"] != "N/A"
              ) {
                if (row["trial-days"] > 0 && row["trial-days"] <= 10)
                  return (
                    <s.TypographyColoredWarning>
                      {row["expiration-date"]}
                    </s.TypographyColoredWarning>
                  );
                if (row["trial-days"] <= 0) {
                  return (
                    <s.TypographyColoredDanger>
                      {row["expiration-date"]}
                    </s.TypographyColoredDanger>
                  );
                }
              }
              return (
                <s.CustomTableCellText>
                  {row["expiration-date"]}
                </s.CustomTableCellText>
              ); // or default icon
            },
            tooltipText: (row) => {
              if (row["trial-days"] != undefined) {
                if (row["trial-days"] > 0)
                  return `${row["trial-days"]} trial days left.`;
                if (row["trial-days"] === 0)
                  return `trial period expires today`;
                if (row["trial-days"] < 0)
                  return `trial period expired ${Math.abs(
                    row["trial-days"]
                  )} days ago`;
              }
              return "";
            }
          },
          {
            withText: false,
            column: "otp-required",
            getIcon: (row) => {
              if (String(row["otp-required"]) === "true")
                return <SuccessIcon color="success" fontSize="small" />;
              if (String(row["otp-required"]) === "false")
                return <FailedIcon color="error" fontSize="small" />;
              return <></>; // or default icon
            },
            tooltipText: (row) => {
              if (row["otp-required"])
                return "2FA activated or action required";
              if (!row["otp-required"]) return "2FA deactivated";
              return "";
            }
          },
          {
            withText: false,
            column: "is-enabled",
            getIcon: (row) => {
              if (String(row["is-enabled"]) === "true")
                return <SuccessIcon color="success" fontSize="small" />;
              if (String(row["is-enabled"]) === "false")
                return <FailedIcon color="error" fontSize="small" />;
              return <></>; // or default icon
            },
            tooltipText: (row) => {
              if (row["is-enabled"]) return "User enabled";
              if (!row["is-enabled"]) return "User disabled";
              return "";
            }
          }
        ]}
        isLoading={!users}
        isServerSortingEnabled={true}
        onServerSort={handleSortChange}
        isSearchEnabled={true}
        isPaginationEnabled={true}
        page={page}
        rowsPerPage={rowsPerPage}
        searchString={search}
        onChangeSearch={handleChangeSearch}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        count={usersTotal || 0}
        toolbarItems={
          <>
            <Tooltip
              title="No permissions"
              disableHoverListener={isUserManagementAllowed}
              placement="top"
              arrow
            >
              <span>
                <Button
                  style={{ marginRight: 10 }}
                  onClick={() => handleDialogOpen(DIALOG_TYPES.CREATE)}
                  disabled={!isUserManagementAllowed}
                  variant={"contained"}
                >
                  Create user
                </Button>
              </span>
            </Tooltip>

            <Tooltip
              title="No permissions"
              disableHoverListener={isBillingManagementAllowed}
              placement="top"
              arrow
            >
              <span>
                <Button
                  onClick={() =>
                    handleDialogOpen(DIALOG_TYPES.GENERATE_BILLING_REPORT)
                  }
                  onClickCapture={() =>
                    handleDialogOpen(DIALOG_TYPES.GENERATE_BILLING_REPORT)
                  }
                  disabled={!isBillingManagementAllowed}
                  variant="contained"
                >
                  Generate billing report
                </Button>
              </span>
            </Tooltip>
            <CustomSelect
              label={"Show"}
              options={USERS_FILTER_OPTIONS}
              defaultValue={"users"}
              onChange={handleChangeFilter}
            />
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={hideDisabledUsers}
                  onChange={handleChangeDisabledUsersFilter}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={<s.ToggleLabel>Hide disabled users</s.ToggleLabel>}
            />
          </>
        }
      />

      <FormDialog
        isOpened={dialog.isOpened}
        isLoading={dialogProps[dialog.type].isLoading}
        onCancel={handleCloseDialog}
        isLocked={dialogProps[dialog.type].isLocked}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
    </>
  );
};

import Link from "@mui/material/Link";
import MuiSnackbar from "@mui/material/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import copy from "copy-to-clipboard";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { FC, useCallback, useState } from "react";
import * as s from "./styles";
import { SnackbarProps } from "./types";

const SNACKBAR_LIFETIME = 6000;

export const Snackbar: FC<SnackbarProps> = ({
  text,
  isOpened,
  type,
  onClose,
  onExited,
  title
}: SnackbarProps) => {
  const [isExtended, setIsExtended] = useState(false);
  const [isTextCopied, setTextIsCopied] = useState(false);

  const handleShowDetails = useCallback(() => {
    setIsExtended(true);
  }, []);

  const handleCopyToClipboardButtonClick = useCallback(() => {
    if (text) {
      copy(text);
      setTextIsCopied(true);
    }
  }, [text]);

  const handleTooltipExited = useCallback(() => {
    setTextIsCopied(false);
  }, []);

  return type === NOTIFICATION_TYPES.PROGRESS ? (
    <MuiSnackbar
      ClickAwayListenerProps={{
        mouseEvent: false,
        touchEvent: false
      }}
      disableWindowBlurListener={true}
      open={isOpened}
      message={
        <s.ProgressNotificationContainer>
          <s.Spinner size={12} color={"inherit"} title={title} />
          {title}
        </s.ProgressNotificationContainer>
      }
      data-testid={"progress-snackbar"}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
    />
  ) : (
    <MuiSnackbar
      ClickAwayListenerProps={{
        mouseEvent: false,
        touchEvent: false
      }}
      disableWindowBlurListener={true}
      open={isOpened}
      autoHideDuration={SNACKBAR_LIFETIME}
      onClose={onClose}
      TransitionProps={{
        onExited
      }}
      data-testid={"snackbar"}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
    >
      <s.Alert onClose={onClose} severity={type}>
        {title && <s.AlertTitle>{title}</s.AlertTitle>}
        {isExtended ? (
          <>
            <Typography variant={"body2"}>{text}</Typography>
            <Tooltip
              title={isTextCopied ? "Copied" : ""}
              onClick={handleCopyToClipboardButtonClick}
              TransitionProps={{
                onExited: handleTooltipExited
              }}
            >
              <Link
                component={"button"}
                onClick={handleCopyToClipboardButtonClick}
              >
                Copy to clipboard
              </Link>
            </Tooltip>
          </>
        ) : (
          text && (
            <Link component={"button"} onClick={handleShowDetails}>
              Show details
            </Link>
          )
        )}
      </s.Alert>
    </MuiSnackbar>
  );
};

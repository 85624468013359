import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import {
  isOrganizationCreatingSelector,
  isOrganizationDeletingSelector,
  isOrganizationUpdatingSelector,
  isAdminAddingToOrganizationSelector,
  isAdminRemovingFromOrganizationSelector,
  isOrganizationOwnerChangingSelector,
  tableOrganizationsSelector
} from "modules/enterprises/selectors";
import { TableOrganization } from "modules/enterprises/types";
import * as pollingActions from "modules/polling/actions";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { validateName } from "utils/validateName";
import { number, string } from "yup";
import {
  ENTITY_NAME_LENGTH,
  ERROR_MESSAGES,
  REGEX,
  ROUTES
} from "../../constants";
import { DIALOG_TYPES } from "./types";
import { appConfig } from "../../appConfig";

const POLL_ID_PREFIX = "ORGANIZATIONS";

const POLL_IDS = {
  organizations: "ORGANIZATIONS"
};

const tableColumns: TableColumn<TableOrganization>[] = [
  { key: "name", label: "Name" },
  { key: "id", label: "ID" },
  { key: "owner", label: "Owner ID" },
  { key: "project_number_limit", label: "Project limit" }
];

const title = "Organizations";

const breadcrumbs: Breadcrumb[] = [
  { text: "Organizations", url: ROUTES.ORGANIZATIONS }
];

export const Organizations: FC = () => {
  const dispatch = useDispatch();
  const organizations = useSelector(tableOrganizationsSelector);
  const isOrganizationCreating = useSelector(isOrganizationCreatingSelector);
  const isOrganizationUpdating = useSelector(isOrganizationUpdatingSelector);
  const isOrganizationDeleting = useSelector(isOrganizationDeletingSelector);
  const isAdminAddingToOrganization = useSelector(
    isAdminAddingToOrganizationSelector
  );
  const isAdminRemovingFromOrganization = useSelector(
    isAdminRemovingFromOrganizationSelector
  );
  const isOrganizationsOwnerChanging = useSelector(
    isOrganizationOwnerChangingSelector
  );
  const isOperationInProgress =
    isOrganizationCreating ||
    isOrganizationUpdating ||
    isOrganizationDeleting ||
    isAdminAddingToOrganization ||
    isAdminRemovingFromOrganization ||
    isOrganizationsOwnerChanging;
  const previousIsOperationInProgress = usePrevious(isOperationInProgress);

  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE, isOpened: false });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const handleDialogOpen = useCallback(
    (dialogType: DIALOG_TYPES, id?: string) => {
      if (id) setSelectedItemId(id);
      setDialog({
        type: dialogType,
        isOpened: true
      });
    },
    []
  );

  const tableActions: TableRowActionsMenuItem<TableOrganization>[] = [
    {
      label: "Edit",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.EDIT_ORGANIZATION, id)
    },
    {
      label: "Add Administrator",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.ADD_ADMIN, id)
    },
    {
      label: "Remove Administrator",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.REMOVE_ADMIN, id)
    },
    {
      label: "Change Ownership",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.CHANGE_OWNERSHIP, id)
    },
    {
      label: "Delete",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.DELETE_ORGANIZATION, id)
    }
  ];

  const handleConfirmCreateOrganization = useCallback(
    (data: { org_name: string; owner_email: string }) => {
      dispatch(
        enterprisesActions.createOrganization.started({
          data: {
            org_name: data.org_name,
            owner_email: data.owner_email
          }
        })
      );
      handleCloseDialog();
    },
    [dispatch, handleCloseDialog]
  );

  const handleConfirmEditOrganization = useCallback(
    (data: { name: string; project_number_limit: string }) => {
      if (selectedItemId) {
        dispatch(
          enterprisesActions.updateOrganization.started({
            id: selectedItemId,
            data: {
              name: data.name,
              project_number_limit: Number(data.project_number_limit)
            }
          })
        );
      }
      handleCloseDialog();
    },
    [dispatch, selectedItemId, handleCloseDialog]
  );

  const handleConfirmAddAdminToOrganization = useCallback(
    (data: { user_email: string }) => {
      if (selectedItemId) {
        dispatch(
          enterprisesActions.addAdminToOrganization.started({
            "org-id": selectedItemId,
            "user-email": data.user_email.toLowerCase()
          })
        );
      }
      handleCloseDialog();
    },
    [dispatch, selectedItemId, handleCloseDialog]
  );

  const handleConfirmRemoveAdminFromOrganization = useCallback(
    (data: { user_id: string }) => {
      if (selectedItemId) {
        dispatch(
          enterprisesActions.removeAdminFromOrganization.started({
            "org-id": selectedItemId,
            "user-id": data.user_id
          })
        );
      }
      handleCloseDialog();
    },
    [dispatch, selectedItemId, handleCloseDialog]
  );

  const handleConfirmChangeOrganizationOwner = useCallback(
    (data: { user_email: string }) => {
      if (selectedItemId) {
        dispatch(
          enterprisesActions.changeOrganizationOwner.started({
            "org-id": selectedItemId,
            "user-email": data.user_email.toLowerCase()
          })
        );
      }
      handleCloseDialog();
    },
    [dispatch, selectedItemId, handleCloseDialog]
  );

  const handleConfirmDeleteOrganization = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        enterprisesActions.deleteOrganization.started({
          id: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [dispatch, selectedItemId, handleCloseDialog]);

  const previousSelectedItemId = usePrevious(selectedItemId);
  const currentItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const currentOrganizationName = organizations?.find(
    (organization) => organization.id === currentItemId
  )?.name;

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE]: {
      onConfirm: handleConfirmCreateOrganization,
      title: "Create organization",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "org_name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
            .matches(REGEX.ORGANIZATION_NAME, ERROR_MESSAGES.ORGANIZATION_NAME)
        },
        {
          name: "owner_email",
          type: FIELD_TYPES.TEXT,
          label: "Owner E-mail",
          rules: string()
            .required()
            .matches(REGEX.EMAIL_ADDRESS, ERROR_MESSAGES.EMAIL_ADDRESS)
        },
        {
          name: "notes",
          type: FIELD_TYPES.NOTES,
          label: `✍ Only already registered users can own organizations`
        }
      ]
    },
    [DIALOG_TYPES.EDIT_ORGANIZATION]: {
      onConfirm: handleConfirmEditOrganization,
      title: `Edit organization`,
      confirmButtonLabel: "Edit",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            organizations?.find(
              (organization) => organization.id === selectedItemId
            )?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
            .matches(REGEX.ORGANIZATION_NAME, ERROR_MESSAGES.ORGANIZATION_NAME)
        },
        {
          name: "project_number_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Project number limit",
          defaultValue:
            organizations?.find(
              (organization) => organization.id === selectedItemId
            )?.project_number_limit || appConfig.defaultAllowedProjectNumber,
          min: appConfig.defaultAllowedProjectNumber,
          rules: number()
            .integer()
            .required()
            .min(
              appConfig.defaultAllowedProjectNumber,
              ERROR_MESSAGES.PROJECTS_NUMBER
            )
        }
      ]
    },
    [DIALOG_TYPES.ADD_ADMIN]: {
      onConfirm: handleConfirmAddAdminToOrganization,
      title: `Add Administrator`,
      confirmButtonLabel: "Add",
      fields: [
        {
          name: "user_email",
          type: FIELD_TYPES.TEXT,
          label: "New Administrator E-mail",
          rules: string()
            .required()
            .matches(REGEX.EMAIL_ADDRESS, ERROR_MESSAGES.EMAIL_ADDRESS)
        },
        {
          name: "notes",
          type: FIELD_TYPES.NOTES,
          label: `✍ Only already registered users can be added`
        }
      ]
    },
    [DIALOG_TYPES.REMOVE_ADMIN]: {
      onConfirm: handleConfirmRemoveAdminFromOrganization,
      title: `Remove Administrator`,
      confirmButtonLabel: "Remove",
      fields: [
        {
          name: "user_id",
          type: FIELD_TYPES.TEXT,
          label: "Administrator ID",
          rules: string()
        },
        {
          name: "info",
          type: FIELD_TYPES.NOTES,
          label: `✍ it is not allowed to delete organization owner`
        }
      ]
    },
    [DIALOG_TYPES.CHANGE_OWNERSHIP]: {
      onConfirm: handleConfirmChangeOrganizationOwner,
      title: `Transfer organization`,
      confirmButtonLabel: "Transfer",
      fields: [
        {
          name: "user_email",
          type: FIELD_TYPES.TEXT,
          label: "New Owner E-mail",
          rules: string()
            .required()
            .matches(REGEX.EMAIL_ADDRESS, ERROR_MESSAGES.EMAIL_ADDRESS)
        },
        {
          name: "info",
          type: FIELD_TYPES.NOTES,
          label: `✍ Organizations can only be transferred its administrators`
        }
      ]
    },
    [DIALOG_TYPES.DELETE_ORGANIZATION]: {
      onConfirm: handleConfirmDeleteOrganization,
      title: `Are you sure you want to delete "${
        currentOrganizationName ?? "selected"
      }" organization?`,
      confirmButtonLabel: "Delete"
    }
  };

  const generateTableItemURL = useCallback(
    (id: string) => generatePath(ROUTES.ORGANIZATION, { organizationId: id }),
    []
  );

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.organizations}`,
        action: enterprisesActions.getOrganizations.started({})
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
  });

  useEffect(() => {
    if (previousIsOperationInProgress && !isOperationInProgress) {
      dispatch(enterprisesActions.getOrganizations.started({}));
    }
  }, [dispatch, previousIsOperationInProgress, isOperationInProgress]);

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Typography variant={"h4"} component={"h2"}>
        {title}
      </Typography>
      <Table
        isSearchEnabled={true}
        isSortingEnabled={true}
        actions={tableActions}
        rows={organizations || []}
        columns={tableColumns}
        itemLink={{
          column: "name",
          getURL: generateTableItemURL
        }}
        isLoading={!organizations}
        toolbarItems={
          <span>
            <Button
              onClick={() => handleDialogOpen(DIALOG_TYPES.CREATE)}
              variant={"contained"}
              disabled={!organizations}
            >
              Create organization
            </Button>
          </span>
        }
      />
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
    </>
  );
};
